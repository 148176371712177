/* Reset some default styles */
body, html {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
  }

  img{
    box-shadow: rgba(246, 26, 250, 0.35) 0px 5px 15px;
  }

  .container h2{
    text-align: center;
    text-transform: uppercase;
  }


  
  /* Header styles */

  .home-prole-mainbox{
    display: grid;
    margin: auto;
    /* text-align: center; */
    justify-content: center;
    align-items: center;
    box-shadow: rgba(246, 26, 250, 0.35) 0px 5px 15px;
    border-radius: 15px;
  }

  .header {
    /* background-color: #333; */
    color: #fff;
    padding: 10px;
    text-align: center;
    border-radius: 15px;
    background: rgb(246,26,250);
background: linear-gradient(122deg, rgba(246,26,250,1) 4%, rgba(17,170,255,1) 100%);
  }

  .homeimg1 img{
    /* width: 50%; */
    height: 250px;
    border-radius: 15px;
    display: flex;
    margin: auto;
    justify-content: center;
    text-align: center;
    align-items: center;
    
  }

  

  .home-achive-btn{
    margin: auto;
    text-align: center;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(246, 26, 250, 0.35) 0px 5px 15px;
    border-radius: 15px;
    padding: 20px;
  }

  .home-profile{
    
    padding: 10px;
    border-radius: 15px;
    margin: 20px;
  }

  .birthday-section h3{
    text-align: center;
    text-transform: uppercase;
  }

  .birth-prole-mainbox{
    display: grid;
    margin: auto;
    /* text-align: center; */
    justify-content: center;
    align-items: center;
    /* box-shadow: rgba(246, 26, 250, 0.35) 0px 5px 15px; */
    /* border-radius: 15px; */
  }

  .birthdayimg1 img{
    /* width: 50%; */
    height: 250px;
    border-radius: 15px;
    display: flex;
    margin: auto;
    justify-content: center;
    text-align: center;
    align-items: center;
    
  }

  .img-common-landscape img{
    width: 400px;
    border-radius: 15px;
    display: flex;
    margin: auto;
    justify-content: center;
    text-align: center;
    align-items: center;
  }
  
  /* Footer styles */
  .footer {
    background: rgb(246,26,250);
background: linear-gradient(122deg, rgba(246,26,250,1) 4%, rgba(17,170,255,1) 100%);
    color: #fff;
    padding: 10px;
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    margin-top: 100px;
  }
  
  /* Example of other common styles */
  .content {
    padding: 20px;
  }
  
  .section {
    margin-bottom: 20px;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .container {
      padding: 10px;
    }
  }
  

  .container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    margin-bottom: 100px;
  }
  
  .birthdays-page {
    margin-top: 20px;
  }
  
  .birthday-section {
    margin-bottom: 40px;
  }
  
  .birthday-section h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .birthday-section h3 {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .birthday-section p {
    line-height: 1.6;
  }
  

  /* More page styles */
.more-page {
    text-align: center;
  }
  
  /* Link item styles */
  .link-item {
    display: inline-block;
    margin: 10px;
    padding: 10px 20px;
    background-color: #007bff; /* Blue background color */
    color: #fff; /* White text color */
    text-decoration: none; /* Remove underline */
    border: 2px solid transparent; /* Transparent border */
    border-radius: 5px; /* Rounded corners */
    transition: all 0.3s ease; /* Smooth transition */
  }
  
  .link-item:hover {
    background-color: #0056b3; /* Darker blue on hover */
    border-color: #0056b3; /* Darker border on hover */
  }
  
  .link-item:focus,
  .link-item:active {
    outline: none; /* Remove default focus outline */
  }

  
  