/* navbar */

.Nav-logo {
    width: 100px;
    height: auto;
    filter: drop-shadow(5px 5px 5px #fff); 
    background-color: #fff;
    border-radius: 15px;
    padding: 5px;
  }
  
  .MuiAppBar-colorPrimary {
    color: #fff;
    background-color: #12bed3 !important;
  }
  
  .navbar {
    background-color: #12bed3;
    color: #fff;
    background: rgb(246,26,250);
background: linear-gradient(122deg, rgba(246,26,250,1) 4%, rgba(17,170,255,1) 100%);
  
  }
  
  .each-slide-effect>div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 350px;
  }
  
  .each-slide-effect span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
  }